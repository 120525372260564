const blockArticleScrollReveal = () => {
  ScrollReveal().reveal(".block-3-article .title", { delay: 600, duration: 1000 }); // eslint-disable-line
  ScrollReveal().reveal(".block-3-article .swiper", { delay: 1000, duration: 1000 }); // eslint-disable-line
};

const blockArticleSlider = () => {
  const blocks = document.querySelectorAll(".block-3-article .swiper");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, {// eslint-disable-line
      // Optional parameters
      loop: true,
      lazy: true,
      speed: 2000,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        320: {
          autoplay: false,
        },
        1024: {
          slidesPerView: 1.6,
          spaceBetween: 40,
          centeredSlides: true,
        },
      },
      // If we need pagination
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  blockArticleScrollReveal();
  blockArticleSlider();
});
